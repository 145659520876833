import { ArrowLeftBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import NumberHelper from '@/core/helpers/number.helper';
import { AspspPaymentDestinationTypeEnum } from '@/core/packages/shared-library';
import { WALLET_STORE } from '@/store/modules/wallet';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    components: {
        ArrowLeftBold,
    },
    emits: ['executeWithdrawForm', 'verifyUserAccount'],
    props: ['loading', 'userCustomerAccount', 'userCustomerCurrentBalance', 'availableBalance'],
    data() {
        // const validatePaymentReference = (rule, value, cb) => {
        //   if (value.length < 6 || value.length > 18) {
        //     // eslint-disable-next-line no-multi-str
        //     return cb(new Error('Payment reference must consist of at least 6 alphanumeric characters that are not all the same. \
        //     Optional, uncounted characters include space, hyphen(-), full stop (.), ampersand(&), and forward slash (/). Total of all characters must be less than 18.'));
        //   }
        //   return cb();
        // };
        const validateWithdrawalAmount = (rule, value, cb) => {
            if (!value || value === 'undefined') {
                return cb(new Error(rule.message));
            }
            // if (value && parseFloat(value) > parseFloat((this as any).getAvailableBalance)) {
            //   return cb(new Error(`You can only withdraw a max amount of ${(this as any).getAvailableBalance}`));
            // }
            return cb();
        };
        return {
            isLoading: false,
            AspspPaymentDestinationTypeEnum,
            withdrawDetail: {
                accountNo: '',
                amount: 0,
                destination: {
                    accountNumber: '',
                    name: '',
                    sortCode: '',
                    type: '',
                },
                name: '',
                recipient: '',
                reference: '',
                sortCode: '',
                sourceAccountId: '',
            },
            defaultWithdrawDetailState: {
                accountNo: '',
                amount: 1,
                destination: {
                    accountNumber: '',
                    name: '',
                    sortCode: '',
                    type: '',
                },
                name: '',
                recipient: '',
                reference: '',
                sortCode: '',
                sourceAccountId: '',
            },
            loadConfirmAccount: false,
            withdrawFormRules: {
                recipient: [
                    { required: true, message: 'This field is required.', trigger: 'blur' },
                ],
                accountNo: [
                    { required: true, message: 'This field is required.', trigger: 'blur' },
                ],
                sortCode: [
                    { required: true, message: 'This field is required.', trigger: 'blur' },
                ],
                amount: [
                    {
                        required: true,
                        validator: validateWithdrawalAmount,
                        /* message: 'This field is required.', */
                        trigger: 'blur'
                    },
                ],
                reference: [
                    {
                        required: true, message: 'This field is required.', trigger: 'blur',
                    },
                ],
            }
        };
    },
    created() {
        this.initialize();
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(WALLET_STORE, ['withdrawalForm']),
        isVerifiedAccount() {
            return typeof this.$route.params.withdrawalVerified !== 'undefined' ? this.$route.params.withdrawalVerified : false;
        },
        getAvailableBalance() {
            return this.userCustomerCurrentBalance;
        },
    },
    methods: {
        ...mapActions(WALLET_STORE, [
            'setMoneyOutRightbar',
            'setMoneyOutVerifyRightbar',
            'setWithdrawalForm'
        ]),
        ...mapActions(USERS_STORE, ['sendVerificationWithdrawal']),
        initialize() {
            if (Object.keys(this.withdrawalForm).length) {
                this.withdrawDetail = this.withdrawalForm;
            }
        },
        onNavigateBack() {
            // this.setWithdrawalForm({});
            this.setMoneyOutRightbar(false);
        },
        isNumber(event, value) {
            return NumberHelper.isNumber(event, value);
        },
        setToDecimalFormat(value) {
            return NumberHelper.convertToDecimal(value);
        },
        async onVerifyWithdrawForm() {
            const isValid = await this.$refs.withdrawForm.validate();
            if (isValid) {
                this.setWithdrawalForm({
                    ...this.withdrawDetail,
                    customerAccountId: this.userCustomerAccount?.accountId
                });
                this.isLoading = true;
                this.sendVerificationWithdrawal(this.user.id)
                    .then(() => {
                    this.setMoneyOutVerifyRightbar(true);
                })
                    .catch(() => {
                    this.$notify.error({
                        title: 'Error',
                        message: 'Sending of verification withdrawal instructions have failed. Please try again later.'
                    });
                });
            }
        }
    }
});
