import { CircleCloseFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import MoneyOutComponent from '@/modules/e-wallet/components/MoneyOutComponent.vue';
import { WALLET_STORE } from '@/store/modules/wallet';
export default defineComponent({
    name: 'notification-drawer',
    components: {
        CircleCloseFilled,
        MoneyOutComponent,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(WALLET_STORE, [
            'openMoneyOutDrawer',
        ]),
    },
    methods: {
        ...mapActions(WALLET_STORE, ['setOpenMoneyOutDrawer']),
        closeDrawer() {
            this.setOpenMoneyOutDrawer(false);
        },
    }
});
