import { CircleCloseFilled, Search } from '@element-plus/icons';
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import DownloadFilterComponent from '@/modules/e-wallet/components/DownloadFilterComponent.vue';
import ListItemComponent from '@/modules/e-wallet/components/ListItemComponent.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USERS_STORE } from '@/store/modules/users';
import { WALLET_STORE } from '@/store/modules/wallet';
export default defineComponent({
    name: 'notification-drawer',
    components: {
        Search,
        ListItemComponent,
        CircleCloseFilled,
        DownloadFilterComponent
    },
    data() {
        return {
            initialData: [],
            transactionLogs: [],
            userCustomerAccount: null,
            userCustomerCurrentBalance: 0,
            userCustomerAccountDetails: null,
        };
    },
    created() {
        this.initialize();
    },
    computed: {
        ...mapGetters(WALLET_STORE, ['openTransactionDetailsDrawer']),
        ...mapGetters(['isTradesperson']),
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
    },
    methods: {
        ...mapActions(WALLET_STORE, ['setOpenTransactionDetailsDrawer']),
        ...mapActions(USERS_STORE, [
            'getUserTransactionLogs',
            'getUserCustomerAccount',
            'getUserCustomerAccountDetails',
            'sendVerificationWithdrawal'
        ]),
        closeDrawer() {
            this.setOpenTransactionDetailsDrawer(false);
        },
        async initialize() {
            const { userId } = this.authenticatedUser;
            this.transactionLogs = await this.getUserTransactionLogs({ userId });
            if (this.isTradesperson) {
                this.userCustomerAccount = await this.getUserCustomerAccount(userId);
                if (this.userCustomerAccount?.accountId) {
                    const { accountId } = this.userCustomerAccount;
                    const response = await this.getUserCustomerAccountDetails({ userId, accountId });
                    const { availableBalance } = response;
                    this.userCustomerAccountDetails = response;
                    this.userCustomerCurrentBalance = availableBalance;
                }
            }
        },
        createdDate(date) {
            const d = new Date(date);
            return moment(d).format('ddd, D YY');
        }
    }
});
