import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { VERIFY_WITHDRAWAL_ROUTE } from '@/modules/dashboard/routes/verify-withdrawal';
import MoneyOutForm from '@/modules/e-wallet/components/MoneyOutForm.vue';
import MoneyOutSuccessComponent from '@/modules/e-wallet/components/MoneyOutSuccessComponent.vue';
import MoneyOutVerificationCodeComponent from '@/modules/e-wallet/components/MoneyOutVerificationCodeComponent.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { PAYMENTS_STORE } from '@/store/modules/payments';
import { USERS_STORE } from '@/store/modules/users';
import { WALLET_STORE } from '@/store/modules/wallet';
export default defineComponent({
    components: {
        MoneyOutForm,
        MoneyOutSuccessComponent,
        MoneyOutVerificationCodeComponent
    },
    data() {
        return {
            userCustomerAccount: null,
            userCustomerCurrentBalance: 0,
            userCustomerAccountDetails: null,
            isLoading: false
        };
    },
    async created() {
        await this.initialize();
    },
    computed: {
        ...mapGetters(WALLET_STORE, [
            'openMoneyOutDrawer',
            'openMoneyOutRightbar',
            'openMoneyOutVerifyRightbar',
            'openMoneyOutSuccessRightbar'
        ]),
        ...mapGetters(['isTradesperson']),
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        getAvailableBalance() {
            return this.userCustomerCurrentBalance;
        },
    },
    methods: {
        ...mapActions(WALLET_STORE, ['setOpenMoneyOutDrawer']),
        ...mapActions(USERS_STORE, [
            'getUserTransactionLogs',
            'getUserCustomerAccount',
            'getUserCustomerAccountDetails',
            'sendVerificationWithdrawal'
        ]),
        ...mapActions(PAYMENTS_STORE, ['executeWithdrawFunds']),
        closeDrawer() {
            this.setOpenMoneyOutDrawer(false);
        },
        async initialize() {
            this.userCustomerAccount = await this.getUserCustomerAccount(this.user.id);
            if (this.userCustomerAccount?.accountId) {
                const { accountId } = this.userCustomerAccount;
                const response = await this.getUserCustomerAccountDetails({ userId: this.user.id, accountId });
                const { availableBalance } = response;
                this.userCustomerAccountDetails = response;
                this.userCustomerCurrentBalance = availableBalance;
            }
        },
        executeWithdrawForm(formData) {
            this.isLoading = true;
            this.executeWithdrawFunds(formData)
                .then((response) => {
                if (response) {
                    // console.log('response..');
                }
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Unsuccessful Withdrawal of Funds',
                    message: 'Error releasing of funds to TP.'
                });
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        verifyUserAccount(data) {
            this.sendVerificationWithdrawal(this.user.id)
                .then(() => {
                this.$router.push({ name: VERIFY_WITHDRAWAL_ROUTE, params: { withdrawalDetails: JSON.stringify(data) } });
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'Sending of verification withdrawal instructions have failed. Please try again later.'
                });
            });
        },
    }
});
