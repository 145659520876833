<template>
    <div class="money-out">
        <div class="money-out__header hide-in-mobile">
            <h2>Money out</h2>
        </div>
        <div class="money-out__success">
            <img src="@/assets/images/e-wallet-transaction-success.png" />
            <div class="">Transaction successful</div>
        </div>

        <el-button class="money-out__next-btn" @click="setMoneyOutSuccessRightbar(false)">Done</el-button>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { WALLET_STORE } from '@/store/modules/wallet';

export default {
  components: {
  },
  data() {
    return {
      amount: null,
      bankSelected: '',
      bankOptions: [
        {
          label: 'Barclay',
          value: 'barclay'
        }
      ]
    };
  },
  methods: {
    ...mapActions(WALLET_STORE, ['setMoneyOutSuccessRightbar'])
  }
};
</script>

<style lang="scss" scoped>
.money-out {
    font-family: "Mullish", Arial;
    color: #0c0f4a;

    &__header {
        display: flex;
        align-items: center;
        gap: 12px;
        font-weight: 700;

        .el-icon {
            cursor: pointer;
        }
    }

    &__success {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 2rem;
margin: 6rem 1rem;
font-weight: 700;
font-size: 24px;
text-align: center;
    }

    &__info {
        text-align: center;
        font-size: 14px;
        margin: 2.5rem;
    }

    .fund-input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__balance {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: rgba(12, 15, 74, 0.5);
        margin-top: -16px;
        margin-bottom: 6rem;

        span {
            font-weight: 700;
            color: #FFA500;
        }
    }

    &__term {
        color: rgba(12, 15, 74, 0.5);
        line-height: 18px;
        letter-spacing: 0.25px;
        text-align: center;
        font-size: 10px;

        span {
            color: #0C0F4A;
        }
    }

    &__next-btn {
        width: 100%;
        background-color: #ffa500;
        font-size: 14px;
        text-transform: uppercase;
        border-radius: 7px;
        color: #fff;
        padding: 14px 20px;
        margin-top: 20px;
    }

    :deep(.money-in__bank .el-input__inner) {
        width: 100%;
        border: none;
        border-bottom: 2px solid rgba(12, 15, 74, 0.2);
        border-radius: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;

        &::placeholder {
            color: rgba(12, 15, 74, 0.5);
            font-weight: 400;
            font-size: 12px;
            line-height: 30px;
        }
    }

    :deep(.el-form-item__label) {
        font-size: 12px;
        color: rgba(12, 15, 74, 0.5);
        margin-bottom: 0;
    }

    :deep(.fund-input .el-input__inner) {
        width: 100%;
        border: none;
        border-bottom: 2px solid rgba(12, 15, 74, 0.1);
        border-radius: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        margin: auto;
        text-align: center;

        &::placeholder {
            color: rgba(12, 15, 74, 0.5);
            font-weight: 400;
            font-size: 12px;
            line-height: 30px;
        }
    }

    :deep(.amount-input .el-input__inner) {
        width: 100%;
        border: none;
        border-bottom: 2px solid rgba(12, 15, 74, 0.2);
        border-radius: 0;
        padding: 0;
        text-align: center;
        font-weight: 700;
        font-size: 30px;
        letter-spacing: .15px;
        line-height: 24px;
        color: #FAA100;
        margin-top: 16px;

        &::placeholder {
            width: 100%;
            border: none;
            border-radius: 0;
            padding: 0;
            text-align: center;
            font-weight: 700;
            font-size: 24px;
            letter-spacing: .15px;
            line-height: 24px;
        }
    }

    .el-select {
        width: 100%;
    }

    .el-form {
        display: flex;
        gap: 12px;
        margin: 8rem 0;

        :deep(.el-input__inner) {
            background-color: rgba(12, 15, 74, 0.05);
        }
    }
}
</style>
