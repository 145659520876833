import { ArrowLeftBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { AspspPaymentDestinationTypeEnum } from '@/core/packages/shared-library';
import { PAYMENTS_STORE } from '@/store/modules/payments';
import { USERS_STORE } from '@/store/modules/users';
import { WALLET_STORE } from '@/store/modules/wallet';
export default defineComponent({
    components: {
        ArrowLeftBold,
    },
    data() {
        return {
            verifyForm: {
                code1: '',
                code2: '',
                code3: '',
                code4: '',
            },
            isLoading: false
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(WALLET_STORE, ['withdrawalForm']),
    },
    methods: {
        ...mapActions(WALLET_STORE, ['setMoneyOutVerifyRightbar', 'setMoneyOutSuccessRightbar']),
        ...mapActions(USERS_STORE, ['sendVerificationWithdrawal', 'verifyUserAccount']),
        ...mapActions(PAYMENTS_STORE, ['executeWithdrawFunds']),
        async onSubmit() {
            this.isLoading = true;
            const { email, id } = this.user;
            const formData = {
                email,
                code: Object.values(this.verifyForm).join().replaceAll(',', ''),
            };
            await this.verifyUserAccount({ formData, userId: id })
                .then(async () => {
                const { amount, accountNo, recipient, sortCode, reference, customerAccountId } = this.withdrawalForm;
                const data = {
                    amount: parseFloat(amount),
                    sourceAccountId: customerAccountId,
                    destination: {
                        accountNumber: accountNo.toString(),
                        name: recipient,
                        sortCode,
                        type: AspspPaymentDestinationTypeEnum.SCAN,
                    },
                    reference,
                };
                await this.executeWithdrawFunds(data)
                    .then((response) => {
                    if (response) {
                        this.$notify.success({
                            message: 'Transaction successful!'
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }
                })
                    .catch(() => {
                    this.$notify.error({
                        title: 'Unsuccessful Withdrawal of Funds',
                        message: 'Error releasing of funds to TP, kindly double check the account you provided '
                    });
                })
                    .finally(() => {
                    // ...
                });
            })
                .catch(() => {
                this.$notify.error({
                    message: 'Verification code does not match.'
                });
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        inputAutoFocus(payload) {
            if (payload.index > 1 && payload.event.length) {
                this.$refs[`code${payload.index}`].focus();
            }
            else {
                this.$refs.code4.blur();
            }
        },
    }
});
