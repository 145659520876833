import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { WALLET_STORE } from '@/store/modules/wallet';
export default defineComponent({
    name: 'confirmation-modal',
    emits: ['close'],
    components: {
        BaseModal,
    },
    props: {
        show: Boolean,
    },
    computed: {
        ...mapGetters(WALLET_STORE, ['openThankyouRegisterInterestModal']),
    },
    methods: {
        ...mapActions(WALLET_STORE, ['setOpenThankyouRegisterInterestModal']),
        cancel() {
            this.$emit('close');
        },
        confirm() {
            this.$emit('close');
        },
    }
});
