export default {
    isNumber(event, value) {
        let currentValue = value;
        let evt = event;
        if (typeof currentValue !== 'undefined' && currentValue !== null) {
            currentValue = currentValue.toString();
        }
        evt = evt ?? window.event;
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
        }
        else if (charCode === 46 && currentValue && currentValue.toString().indexOf('.') !== -1) {
            evt.preventDefault();
        }
        else if (currentValue && currentValue.indexOf('.') > -1 && (currentValue.split('.')[1].length > 1)) {
            const selection = window.getSelection();
            if (selection && !(selection.type === 'Range' && selection.anchorNode === evt.target.parentElement)) {
                evt.preventDefault();
            }
        }
    },
    convertToDecimal(value) {
        let numberValue = parseFloat(value);
        if (Number.isNaN(numberValue)) {
            numberValue = 0;
        }
        return numberValue.toFixed(2);
    },
};
