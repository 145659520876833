import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import MobileMoneyTransactionComponent from '@/modules/e-wallet/components/MobileMoneyTransactionComponent.vue';
import MobileTransactionCardComponent from '@/modules/e-wallet/components/MobileTransactionCardComponent.vue';
import MoneyOutDrawer from '@/modules/e-wallet/components/MoneyOutDrawer.vue';
import RegisterInterestConfirmationModal from '@/modules/e-wallet/components/RegisterInterestConfirmationModal.vue';
import RegisterInterestThankyouModal from '@/modules/e-wallet/components/RegisterInterestThankyouModal.vue';
import TransactionCardComponent from '@/modules/e-wallet/components/TransactionCardComponent.vue';
import TransactionDetailsDrawer from '@/modules/e-wallet/components/TransactionDetailsDrawer.vue';
// import AtmCardContainer from '@/modules/property-details-children/components/AtmCardContainer.vue';
import FlexColumnWithHeaderSlot from '@/modules/property-details-children/components/FlexColumnWithHeaderSlot.vue';
// import MobileAtmCardComponent from '@/modules/property-details-children/components/MobileAtmCardComponent.vue';
import { PAYMENTS_STORE } from '@/store/modules/payments';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { USERS_STORE } from '@/store/modules/users';
import { WALLET_STORE } from '@/store/modules/wallet';
export default defineComponent({
    name: 'e-wallet-page',
    components: {
        FlexColumnWithHeaderSlot,
        // AtmCardContainer,
        TransactionCardComponent,
        RegisterInterestConfirmationModal,
        RegisterInterestThankyouModal,
        // MobileAtmCardComponent,
        MobileTransactionCardComponent,
        TransactionDetailsDrawer,
        MobileMoneyTransactionComponent,
        MoneyOutDrawer
    },
    data() {
        return {
            showModal: false,
            cards: [
                {
                    id: 1,
                    src: 'example-card.png',
                }
            ],
            histories: [
                {
                    id: 1,
                    name: 'Door repair',
                    stage: 'Stg 1',
                    sum: '+ £500.00',
                    group: 'Today'
                },
                {
                    id: 1,
                    name: 'Door repair',
                    stage: 'Stg 1',
                    sum: '+ £500.00',
                    group: 'Today'
                },
                {
                    id: 1,
                    name: 'Door repair',
                    stage: 'Stg 1',
                    sum: '+ £500.00',
                    group: 'Yesterday'
                },
                {
                    id: 1,
                    name: 'Door repair',
                    stage: 'Stg 1',
                    sum: '+ £500.00',
                    group: 'Yesterday'
                },
                {
                    id: 1,
                    name: 'Door repair',
                    stage: 'Stg 1',
                    sum: '+ £500.00',
                    group: 'Yesterday'
                },
                {
                    id: 1,
                    name: 'Door repair',
                    stage: 'Stg 1',
                    sum: '+ £500.00',
                    group: 'Yesterday'
                },
                {
                    id: 1,
                    name: 'Door repair',
                    stage: 'Stg 1',
                    sum: '+ £500.00',
                    group: '20 May 2023'
                },
                {
                    id: 1,
                    name: 'Door repair',
                    stage: 'Stg 1',
                    sum: '+ £500.00',
                    group: '20 May 2023'
                },
                {
                    id: 1,
                    name: 'Door repair',
                    stage: 'Stg 1',
                    sum: '+ £500.00',
                    group: '20 May 2023'
                }
            ]
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        ...mapGetters(['openEwalletConfirmInterestModal', 'openEwalletThankYouModal']),
        ...mapGetters(PAYMENTS_STORE, ['paymentForm', 'paymentProviders']),
        ...mapGetters(PROPERTY_STORE, ['servicesOnMenu']),
        ...mapGetters(WALLET_STORE, ['openRegisterInterestModalConfirmation', 'openThankyouRegisterInterestModal']),
    },
    methods: {
        ...mapActions(['setEwalletConfirmInterestModal', 'setEwalletThankYouModal']),
        ...mapActions(PAYMENTS_STORE, ['getPaymentProviders', 'executeWithdrawFunds']),
        ...mapActions(WALLET_STORE, ['setOpenRegisterInterestModalConfirmation', 'setOpenThankyouRegisterInterestModal']),
        handlePaymentProviders() {
            this.getPaymentProviders().then(() => {
            }).catch(() => {
            });
        }
    }
});
